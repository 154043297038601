<template>
    <div id="NavigationComponent">
        <div class="d-flex">
            <a :href="linkChat" class="nav-link position-relative py-0 px-2" aria-haspopup="true" aria-expanded="false" title="Messagerie">
                <i class="fa-regular fa-comments h4"></i>
                <sup class="badge badge-fuel-yellow exponent ml-2" v-show="messagesCount > 0">{{ messagesCount }}</sup>
            </a>
            <a href="#" class="nav-link dropdown-toggle position-relative py-0 px-2" role="button" aria-haspopup="true" aria-expanded="false" @click.prevent="toggleOpened()" title="Notifications">
                <i class="fa-regular fa-bell icon h4"></i>
                <sup class="badge badge-fuel-yellow exponent" v-show="total > 0">{{ total }}</sup>
            </a>
        </div>
        <div class="dropdown-menu dropdown-menu-right notifications-dropdown overflow-auto" :class="{'show': opened }">
            <div class="notifications-list" id="NotificationList">
                <div v-if="total > 0">
                    <div class="d-flex justify-content-end" v-if="total > 1">
                        <button @click="destroyAllNotifications()" class="btn btn-delete btn-rounded btn-fuel-yellow m-2">Tout supprimer</button>
                    </div>
                    <div class="notification" v-for="notification in notifications" :key="notification.id">
                        <div class="d-flex align-items-center w-100">
                            <div class="p-2 notifications-icon">
                                <i v-if="notificationType(notification)" class="fa-regular fa-clock my-auto"></i>
                                <i v-else class="fa-regular fa-bell my-auto"></i>
                            </div>
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <div v-if="notification.requires_action" class="col-9 d-flex flex-column ml-3">
                                    <div class="d-flex">
                                        <a :href="notification.link" class="color-black mr-3">{{ notification.text }}</a>
                                        <button @click.prevent="accept(notification)" class="color-black btn btn-rounded btn-blue-calypso mr-2"><i class="fa-solid fa-check"></i></button>
                                        <button @click.prevent="deny(notification)" class="btn btn-rounded btn-dugong mr-2"><i class="fa-solid fa-ban"></i></button>
                                    </div>
                                    <small class="px-1">{{ notification.created_at }}</small>
                                </div>
                                <div class="col-9 ml-3" v-else>
                                    <a :href="notification.link" class="color-black d-flex flex-column h5 mb-1">
                                        {{ notification.text }}
                                        <small>{{ notification.created_at }}</small>
                                    </a>
                                </div>
                                <div>
                                    <button @click.prevent="destroyNotification(notification)" class="btn hover-blue-calypso"><i class="fa-solid fa-trash cursor-pointer"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="notification border-0 text-center" :class="{'d-none': page > lastPage }">
                        <a href="#" @click.prevent="fetchNextPage()">Charger plus de notifications</a>
                    </div>
                </div>
                <div class="notification border-0" v-else>
                    <p class="text-center notification-size">Aucune notification</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { DateTime } from 'luxon';
    import {Notification} from '@models';

    const i18n = {
        "fr": {
            "notification": {
                "applicant_application": "Un candidat a déposé une candidature sur l'une de vos offres",
                "corresponding_job": "Une nouvelle offre pourrait vous intéresser",
                "center_booklet": "Un livret établissement est à disposition sur votre espace",
                "job_begin_date_passed": "La date de début de l'une de vos offres d'emploi est passée, est-elle toujours d'actualité ?",
                "job_begin_date_passed_reminder": "La date de début de l'une de vos offres d'emploi est passée depuis 15 jours, est-elle toujours d'actualité ?",
                "new_message": "Nouveau message de ",
                "quick_intent_processed": "Un de vos contrats a été traité par Ettic",
                "received_impersonation_request": " souhaite accéder à votre compte.",
                "reported_favorite_deletion": " a été retiré de vos favoris suite à son signalement par l'un de vos établissements.",
                "time_sheet_modified": 'L\'un de vos relevés d\'heures a été modifié par l\'établissement.',
                "suggest_applicant": 'Un nouveau candidat vous est proposé par Ettic.',
                "trade_union": {
                    "new_media": "Un nouveau document a été publié pour le syndicat "
                }
            }
        }
    };

    export default {
        name: 'NotificationTray',

        props: {
            isRecruter: {
                type: Boolean,
                default: false
            },
            messagesCount: {
                type: Number,
                default: 0,
            }
        },

        data() {
            return {
                notifications: [],
                page: 1,
                loading: false,
                lastPage: 1,
                total: 0,
                opened: false,
            };
        },

        mounted() {
            this.fetchNotifications();

            const listElm = document.querySelector('#NotificationList').parentNode;

            listElm.addEventListener('scroll', e => {
                if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                    this.fetchNextPage();
                }
            });
        },

        computed: {
            linkChat() {
                if (this.isRecruter) {
                    return route('backoffice.chat');
                }
                return route('applicant.chat');
            },
        },

        methods: {
            fetchNotifications() {
                this.loading = true;

                if (this.page <= this.lastPage) {
                    axios.get(
                        route('api.notification.index'),
                        { params: { page: this.page } }
                    ).then((response) => {
                        this.notifications = this.notifications.concat(Notification.make(response.data.data));
                        this.lastPage = response.data.meta.last_page;
                        this.total = response.data.meta.total;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },

            fetchNextPage() {
                this.page = this.page + 1;
                this.fetchNotifications();
            },

            formatDate(date) {
                if (date) {
                    return DateTime.fromISO(date).toRelative();
                }
            },

            destroyNotification(notification) {
                notification.destroy()
                    .then((response) => {
                        this.notifications = this.notifications.filter((n) => n.id !== notification.id);
                        this.total = response.data.total;
                        this.lastPage = response.data.last_page;
                    })
                ;
            },

            destroyAllNotifications() {
                (new Notification())
                    .destroyAll()
                    .then((response) => {
                        this.notifications = [];
                        this.total = response.data.total;
                        this.lastPage = response.data.last_page;
                        this.page = 0;
                    })
                ;
            },

            accept(notification) {
                notification.accept()
                    .then((response) => {
                        console.log(response);
                        this.destroyNotification(notification);
                    })
                ;
            },

            deny(notification) {
                notification.deny()
                    .then((response) => {
                        console.log(response);
                        this.destroyNotification(notification);
                    })
                ;
            },

            toggleOpened() {
                let navbarBlock = $('#navbarSupportedContent');
                this.opened && navbarBlock.hasClass('show') ? this.opened = true : this.opened = !this.opened;
                navbarBlock.removeClass('show');
            },

            close() {
                if (this.opened) {
                    this.opened = false;
                }
            },

            $t(key) {
                return _.get(i18n.fr, key, key);
            },

            notificationType(notification) {
                let notificationsTab = [
                    'App\\Notifications\\Applicant\\TimeSheetModified',
                    'App\\Notifications\\Applicant\\TimeSheetPendingValidationAvailable',
                    'App\\Notifications\\BackOffice\\TimeSheetPendingValidationAvailable',
                    'App\\Notifications\\Applicant\\RemindApplicantTimeSheetsAvailable',
                    'App\\Notifications\\BackOffice\\RemindRecruiterTimeSheetsAvailable',
                ];
                return notificationsTab.includes(notification.type) ? true : false;
            }
        },
    }
</script>

<style scoped lang="scss">
    .notifications-dropdown {
        margin-top: 20px;
        overflow-y: auto;

        @media screen and (max-width: 991px){
          margin: 0;
          width: 100%;
        }

        @media screen and (max-width: 991px) {
            height: 100vh;
        }

        &.dropdown-menu {
            @media screen and (max-width: 991px) {
                border: none;
            }

            @media screen and (min-width: 576px) and (max-width: 991px) {
                box-shadow: 0 30px 5px -2px #ecf7f8;
            }
        }

        .notifications-list {
            max-height: 38vh;

            @media screen and (max-width: 575px) {
                & > div {
                    padding-bottom: 130px;
                }
            }

            .btn-delete {
                color: #fff;

                &:hover {
                    color: rgba(0, 0, 0, 0.5);
                }
            }

            .notification {
                padding: 0.75rem 1.25rem;
                transform: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                border-width: 0 0 1px;

                p, a {
                    font-size: 0.8rem;
                    margin: 0;
                }

                .notification-size {
                    @media (width < 992px) {
                        font-size: 1.2rem;
                    }
                }

                &:hover {
                    transform: none;
                    box-shadow: none;
                    transition: none;
                    z-index: inherit;
                    background-color: #ecf7f8;

                    & .notifications-icon {
                        border-color: #53acb6 !important;

                        & i {
                            color: #53acb6;
                        }
                    }
                }

                .notifications-icon {
                    border-radius: 50%;
                    border: 1px solid black;
                    text-align: center;

                    i {
                        font-size: 1.2rem;
                    }
                }

                .btn {
                    padding: 0;
                }

                .fa-trash, small {
                    color: rgba(0, 0, 0, 0.5);

                    &:hover {
                        color: #53acb6;
                    }
                }
            }
        }
    }
</style>
