import Model from './Model';
import axios from 'axios';

export default class Notification extends Model {
    destroy() {
        return axios.delete(route('api.notification.destroy', [this.id]));
    }

    destroyAll() {
        return axios.post(route('api.notification.deleteAll'));
    }

    accept() {
        if (this.type === "App\\Notifications\\BackOffice\\ImpersonationRequestNotification") {
            return axios.put(
                route('backoffice.impersonation.update', { 'id': this.data.impersonation.id }),
                {'status': 'accept'}
            );
        }
    }

    deny() {
        if (this.type === "App\\Notifications\\BackOffice\\ImpersonationRequestNotification") {
            return axios.put(
                route('backoffice.impersonation.update', { 'id': this.data.impersonation.id}),
                {'status': 'deny'}
            );
        }
    }
}
